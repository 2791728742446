export const fields = [
    { key: 'index', label: "No", _style:'min-width:30px'},
    { key: 'select', label: "", _style:'min-width:30px' },
    { key: 'full_name', label: "Nama", _style:'min-width:100px' },
    { key: 'email', label: "E-Mail", _style:'min-width:100px' },
    { key: "phone_number", label: "No. HP", _style:'min-width:50px'},
    { key: 'created_at', label: "Tanggal Dibuat", _style:'min-width:100px' },
    { key: 'updated_at', label: "Tanggal Diubah", _style:'min-width:100px' },
    { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]
